<template>
  <b-row class="my-2">
    <b-col cols="12" md="3" class="mb-2">
      <div>
          <b-img
            fluid
            width="350"
            height="300"
            :src="image"
          />
          <div class="side-note">
            <h3 class="side-note-title">{{ pastor }} &</h3>
            <h3 class="side-note-title">{{ wife }}</h3>
          </div>
        </div>
    </b-col>

    <b-col cols="12" md="9">
      <div class="w-100 flex-fill flex-grow-1" style="flex-grow: 1; width: 100%">
          <h2 style="font-weight: bold; color: #1735bf;">
            {{ sectorName }}
          </h2>
          <div class="text-black text-wrap" style="font-size: large;">
            <p>{{ text }}</p>
          </div>

          <div class="stats d-flex flex-column mt-1" style="gap: 10px; max-width: 500px;">
            <div v-for="stat of stats" :key="stat" class="stat">{{ stat }}</div>
          </div>

          <SchoolsAccordion style="max-width: 500px;" :hide-schools="hideSchools" :schools="schools" :sector-name="sectorName" :title="title" />
        </div>
    </b-col>
  </b-row>

  <!-- <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="m-1">
      <div class="d-flex flex-wrap flex-lg-nowrap" style="gap: 10px">
        <div>
          <b-img
            fluid
            width="350"
            height="300"
            :src="image"
            style="min-width: 350px;"
          />
          <div class="side-note">
            <h3 class="side-note-title">{{ pastor }} &</h3>
            <h3 class="side-note-title">{{ wife }}</h3>
          </div>
        </div>

        <div class="w-100 flex-fill flex-grow-1" style="flex-grow: 1; width: 100%">
          <h2 style="font-weight: bold; color: #1735bf;">
            {{ sectorName }}
          </h2>
          <div class="text-black text-wrap" style="font-size: large;">
            <p>{{ text }}</p>
          </div>

          <div class="stats d-flex flex-column mt-1" style="gap: 10px; max-width: 500px;">
            <div v-for="stat of stats" :key="stat" class="stat">{{ stat }}</div>
          </div>

          <SchoolsAccordion style="max-width: 500px;" :hide-schools="hideSchools" :schools="schools" :sector-name="sectorName" :title="title" />
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BCollapse,
  // BNavbarToggle,
  // vBToggle,
  VBToggle,
} from "bootstrap-vue";

import AboutUsHero from "@/@core/components/public/AboutUsHero.vue";
import SchoolsAccordion from "@/@core/components/sector/SchoolsAccordion.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AboutUsHero,
    BButton,
    BCollapse,
    SchoolsAccordion
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    image: {
      required: true
    },
    schools: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      required: true
    },
    stats: {
      type: Array,
      default: () => [],
    },
    titleOfSchoolsSection: {
      type: String,
      default: "Pensa Coordinating Schools"
    },
    pastor: {
      type: String,
      default: ''
    },
    wife: {
      type: String,
      default: ''
    },
    sectorName: {
      type: String,
      default: ''
    },
    hideSchools: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'List of Tertiary Schools'
    }
  },
};
</script>

<style lang="scss" scoped>
.accordion-title {
  background-color: #021977;
  color: #ffd801;
  width: 200px;
  display: flex;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-center;
  align-items: center;
}

.rod-accordion {
  margin-left: 130px;
  // margin-right: 500px;
  width: 50vw;
  height: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: gray;
}

.stat {
  background-color: #899ff5;
  border-radius: 5px;
  color: black;
  padding: 5px;
  font-size: 1.25rem;
}
.side-note {
  position: absolute;
  bottom: 2px;
  // left: 31.5%;
  right: 0px;
  background-color: #021977;
  color: white;
  width: 240px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // border-radius: 15px;
  border-left: 10px solid #ffd801;
  font-weight: bolder;
}

.side-note-title {
  color: white;
  font-size: 15px;
}

.side-note-2 {
  position: relative;
  bottom: 52%;
  left: 135%;
  font-size: medium;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
}

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 15px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}
</style>
