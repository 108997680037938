<template>
  <div
    v-if="!hideSchools"
    class="d-flex w-100 mt-1 mb-4 flex-column align-items-center justify-content-center"
  >
    <div v-if="schools.length > 0" style="width: 100%;">
      <div
        style="display: flex; width: 100%; border-radius: 5px; background-color: gainsboro;"
      >
        <div class="accordion-title">
          <span class="inline-block">View</span>
        </div>
        <div
          style="display: flex; justify-content: space-between; width: 100%; align-items: center;"
        >
          <h3 class="px-2" style="color: #999ca3;">
            {{ title }}
          </h3>
          <div
            v-b-toggle:[sectorName]="sectorName"
            style="padding: 5px; display: flex;"
            @click="expanded = !expanded"
          >
            <feather-icon
              v-if="expanded"
              color="#021977"
              size="30"
              icon="ChevronUpIcon"
            />
            <feather-icon
              v-else
              color="#021977"
              size="30"
              icon="ChevronDownIcon"
            />
          </div>
        </div>
      </div>
      <b-collapse
        :id="sectorName"
        style="background-color: #dcdcdc; max-height: 200px; overflow-y: scroll;"
      >
        <ul class="font-lg" style="font-size: 14px;">
          <li v-for="school of schools" :key="school" style="padding: 3px;">
            {{ school }}
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BCollapse,
  // BNavbarToggle,
  // vBToggle,
  VBToggle,
} from "bootstrap-vue";

import AboutUsHero from "@/@core/components/public/AboutUsHero.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AboutUsHero,
    BButton,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    schools: {
      type: Array,
      default: () => [],
    },
    hideSchools: {
      type: Boolean,
      default: false,
    },
    sectorName: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: 'List of Tertiary Schools'
    }
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion-title {
  background-color: #021977;
  color: #ffd801;
  width: 200px;
  display: flex;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-center;
  align-items: center;
}

.rod-accordion {
  margin-left: 130px;
  // margin-right: 500px;
  width: 50vw;
  height: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: gray;
}

.stat {
  background-color: #899ff5;
  border-radius: 5px;
  color: black;
  padding: 5px;
  font-size: 1.25rem;
}
.side-note {
  position: relative;
  bottom: 12%;
  left: 31.5%;
  background-color: #021977;
  color: white;
  width: 240px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // border-radius: 15px;
  border-left: 10px solid #ffd801;
  font-weight: bolder;
}

.side-note-title {
  color: white;
  font-size: 15px;
}

.side-note-2 {
  position: relative;
  bottom: 52%;
  left: 135%;
  font-size: medium;
}

h1 {
  font-size: 3rem;
  font-weight: normal;
}

.text-yellow {
  color: yellow;
}
.text-lg {
  font-size: 15px;
}
.img-container {
  height: 400px;
  widows: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

ol {
  font-size: 15px;
  li {
    margin-bottom: 10px;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}
</style>
